import { useSelector } from 'react-redux';
import {
	Home as HomeIcon,
	Explore,
	VideoLibrary,
	History,
	LibraryMusic,
	SportsFootball,
	SportsEsports,
	Movie,
	Newspaper,
	LiveTv,
	ReportGmailerrorred,
	LiveHelp,
	SettingsBrightness,
	Person,
} from '@mui/icons-material';

import { Container, Img, Item, Login, Title, Wrapper } from './Sidebar.styled';
import { SCLink, Hr, LinkBtn, Logo } from '../UI';

import LogoImg from '../../assets/logo.webp';

export const Sidebar = ({ changeTheme, isDarkMode, open, children }) => {
	const userData = useSelector((state) => state.user.data);

	return (
		<Container open={open}>
			<Wrapper>
				{children}
				<Logo>
					<SCLink to='/'>
						<Img src={LogoImg} /> VoyeurTube
					</SCLink>
				</Logo>
				<SCLink to='/'>
					<Item>
						<HomeIcon />
						Home
					</Item>
				</SCLink>
				<a href={"https://voyeurhouse.com/"}>
					<Item>
						<Explore />
						Explore
					</Item>
				</a>
				<Hr />
				<SCLink to='hot'>
					<Item>
						<VideoLibrary />
						Video
					</Item>
				</SCLink>
				<a href={"https://voyeurhouse.com/live"}>
					<Item>
						<History />
						RealLiveCams
					</Item>
				</a>
				<Hr />
				{userData ? null : (
					<>
						<Login>
							Sign in to like videos, comment, and subscribe.
							<LinkBtn to='signin'>
								<Person />
								SIGN IN
							</LinkBtn>
						</Login>
						<Hr />
					</>
				)}

				<Title>Best of VoyeurTube</Title>
				<Item>
					<LibraryMusic />
					Voyeur House
				</Item>
				<Item>
					<SportsFootball />
					Voyeur House TV
				</Item>
				<Item>
					<SportsEsports />
					Camarads
				</Item>
				<Item>
					<Movie />
					Live porn
				</Item>
				<Item>
					<Newspaper />
					Hotscope
				</Item>
				<Item>
					<LiveTv />
					Live
				</Item>
				<Hr />
				<a href={"https://voyeurhouse.com/cms/privacy"}>
					<Item>
						<ReportGmailerrorred />
						Privacy Policy
					</Item>
				</a>
				<a href={"https://voyeurhouse.com/cms/tos"}>
					<Item>
						<LiveHelp />
						Terms of Service
					</Item>
				</a>
				<Item onClick={changeTheme}>
					<SettingsBrightness />
					{isDarkMode ? 'Light ' : 'Dark '}theme
				</Item>
			</Wrapper>
		</Container>
	);
};
