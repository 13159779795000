// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: process.env.REACT_APP_APIKEY,
	authDomain: "tube-8b200.firebaseapp.com",
	projectId: "tube-8b200",
	storageBucket: "tube-8b200.appspot.com",
	messagingSenderId: "895691456516",
	appId: "1:895691456516:web:c01ecde3c2ba81a11af551",
	measurementId: "G-12F893V867"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const provider = new GoogleAuthProvider();

export default app;
